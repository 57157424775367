import { nanoid } from "nanoid";
import OptionDisplay from "../components/OptionDisplay";
import Fade from "../components/Fade";
import ConfigureTitle from "../components/ConfigureTitle";
import { useEffect } from "react";

function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

const AddOptions = ({ formState, update, msg }) => {
  const options = formState.options || [];

  const hasBlank = options.some((o) => isBlank(o.title));
  const hasDupes =
    [...new Set(options.map((o) => o.title))].length !== options.length;

  useEffect(() => {
    const lastOption = options[options.length - 1];
    if (lastOption) {
      const input = document.getElementById(`option-${lastOption.id}`);
      if (input) {
        input.focus();
      }
    }
  }, [options.length]);

  const handleAdd = () => {
    if (!hasBlank)
      update("options", [
        ...options,
        { title: "", description: "", id: nanoid() },
      ]);
  };

  const handleRemove = (id) => {
    update(
      "options",
      options.filter((o) => o.id !== id)
    );
  };

  const italic = !isBlank(formState.description) ? "" : "italic";

  return (
    <Fade>
      <div className="flex flex-col space-y-2">
        <ConfigureTitle
          title="Set Options"
          description="Add at least two unique options"
        />
        <div className="flex flex-col space-y-4">
          <div className="flex flex-col p-3 px-4 bg-stone-200 rounded-lg space-y-2">
            <span className="text-stone-700 text-xl">{formState.title}</span>
            <span
              className={"text-stone-700 text-md text-opacity-70 " + italic}>
              {formState.description || "No description"}
            </span>
          </div>

          <div className="flex flex-col space-y-2">
            {options.map((o, i) => (
              <OptionDisplay
                n={i}
                update={(newo) => {
                  update(
                    "options",
                    options.map((op) => (op.id === o.id ? newo : op))
                  );
                }}
                option={o}
                key={o.id}
                remove={() => handleRemove(o.id)}
                onEnter={handleAdd}
              />
            ))}
            <button
              disabled={hasBlank || hasDupes}
              onClick={handleAdd}
              className="rounded-lg bg-stone-50 p-2 w-full border-dotted border-2 border-stone-300 disabled:text-stone-300 disabled:border-stone-100 transition-all">
              <div className="flex space-x-2 items-center justify-center text-stone-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="size-4">
                  <path d="M10.75 4.75a.75.75 0 0 0-1.5 0v4.5h-4.5a.75.75 0 0 0 0 1.5h4.5v4.5a.75.75 0 0 0 1.5 0v-4.5h4.5a.75.75 0 0 0 0-1.5h-4.5v-4.5Z" />
                </svg>
                <span>Add Option</span>
              </div>
            </button>
          </div>

          {msg && (
            <div className="flex text-red-400 justify-center border border-red-400 rounded-md p-3">
              {msg}
            </div>
          )}
        </div>
      </div>
    </Fade>
  );
};

export default AddOptions;
