const ConfigureTitle = ({ title, description }) => {
  return (
    <div className="flex flex-col space-y-2">
      <div className="text-3xl sm:text-4xl font-serif font-bold">{title}</div>
      <span className="text-stone-700 text-md text-opacity-70 h-14">{description}</span>
    </div>
  );
};

export default ConfigureTitle;
