import { Suspense, lazy } from "react";
import CreateTopic from "./routes/CreateTopic";
import Footer from "./components/Footer";
import Loading from "./pages/Loading";
import Feedback from "./pages/Feedback";

// Lazy load the Topic component
const Topic = lazy(() => import("./routes/Topic"));

function App() {
  const path = window.location.pathname.slice(1);
  console.log(path);

  return (
    <>
      <div className="flex flex-col bg-stone-100 text-stone-700 min-h-screen">
        {path === "feedback" ? (
          <Feedback />
        ) : path ? (
          <Suspense fallback={<Loading />}>
            <Topic />
          </Suspense>
        ) : (
          <CreateTopic />
        )}
        <div className="flex-1" />
        <Footer />
      </div>
    </>
  );
}

export default App;
