const TextInput = ({
  title,
  value,
  onChange,
  placeholder,
  onEnter = () => {},
  error = false,
  id = null,
}) => {
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && onEnter) {
      e.preventDefault();
      onEnter();
    }
  };
  if (id) console.log(id);

  const errStyle = error
    ? "border-red-500 hover:border-red-500 focus-within:border-red-500 focus-within:hover:border-red-500"
    : "";
  return (
    <label
      className={
        `
        group relative
        w-full
        bg-stone-200 
        border-2
        border-stone-200
        rounded-lg
        text-stone-700
        transition-all
        ease-in-out
        hover:bg-stone-300
        hover:border-stone-300
        focus-within:outline-none
        focus-within:border-stone-400
        focus-within:hover:border-stone-400
        cursor-pointer
        flex flex-col py-2.5 px-4 ` + errStyle
      }>
      <span className="font-bold text-md">{title}</span>
      <input
        className="bg-transparent w-full placeholder-stone-700 placeholder-opacity-40"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        id={id}
      />
    </label>
  );
};

export default TextInput;
