import { useState, useEffect } from "react";

const usePersistentState = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const value = sessionStorage.getItem(key);
    return value ? JSON.parse(value) : defaultValue;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [state, key]);

  const clear = () => {
    sessionStorage.removeItem(key);
  };

  return [state, setState, clear];
};

export default usePersistentState;
