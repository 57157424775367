import { trashCan } from "../utils/badges";
import { useState, useRef, useEffect } from "react";
import ConfigureTitle from "../components/ConfigureTitle";
import Fade from "../components/Fade";
import phoneValidator from "../utils/phoneValidator";
import listPretty from "../utils/listPretty";
import TextInput from "../components/TextInput";
import E164format from "../utils/E164format";

const pretty = (phone) => {
  let digitsOnly = phone.replace(/\D/g, "");
  if (digitsOnly.length === 10) {
    digitsOnly = "1" + digitsOnly;
  }
  return listPretty(digitsOnly);
};

const ListItem = ({ phone, handleDelete, handleToggle, selected }) => {
  const selectedStyle = selected ? "bg-stone-700 text-stone-200" : "";
  return (
    <div
      className={
        "flex p-1 justify-between items-center rounded-md " + selectedStyle
      }>
      <div className="">{pretty(phone)}</div>
      <div className="flex space-x-4">
        <button
          onClick={() => handleToggle(phone)}
          className="text-opacity-70 underline">
          {selected ? "Remove admin*" : "Set admin*"}
        </button>
        <button onClick={handleDelete} className="text-opacity-70 underline">
          {trashCan}
        </button>
      </div>
    </div>
  );
};

const List = ({ formState, update }) => {
  const [newNumber, setNewNumber] = useState("");
  const [paste, _setPaste] = useState(
    formState.list.map(listPretty).join("\n")
  );
  const [showPaste, setShowPaste] = useState(false);
  const [valid, setValid] = useState(true);
  const digitPosition = useRef(0);
  const textareaRef = useRef(null);
  const wasValid = useRef(false);

  if (formState.security !== "WHITELIST") throw new Error("SHOUDL NOT BE HERE");

  const list = formState.list;

  const syncDigitPosition = () => {
    if (!textareaRef.current) {
      console.error("Textarea ref not found");
      return;
    }

    const text = textareaRef.current.value;
    let currentDigits = 0;
    let textPosition = text.length;

    for (let i = 0; i < text.length; i++) {
      if (/\d/.test(text[i])) {
        if (currentDigits === digitPosition.current) {
          textPosition = i;
          break;
        }
        currentDigits++;
      }
    }
    textareaRef.current.setSelectionRange(textPosition, textPosition);
  };

  useEffect(() => {
    if (showPaste) {
      _setPaste(formState.list.map(listPretty).join("\n"));
      setTimeout(syncDigitPosition, 0);
    }
  }, [showPaste, formState.list]);

  const hasAlready = list.includes(E164format(newNumber));

  const handleAdd = () => {
    if (!phoneValidator(E164format(newNumber)) || hasAlready) return;
    const formatted = E164format(newNumber);
    update("list", [...list, formatted]);
    setNewNumber("");
  };

  const handleToggle = (phone) => {
    update("phone", formState.phone === phone ? "" : phone);
  };

  const handleRemove = (phone) => {
    update(
      "list",
      list.filter((p) => p !== phone)
    );
    if (formState.phone === phone) {
      update("phone", "");
    }
  };

  const setPaste = (value) => {
    const processed = value.split("\n").filter((a) => a.length);
    const valid = processed.every(phoneValidator);
    const different = () =>
      list.map(E164format).join("\n") !== processed.map(E164format).join("\n");

    setValid(valid);

    if (valid && (!wasValid.current || different())) {
      //preserve cursor position relative to digits only
      const position = value
        .slice(0, textareaRef.current.selectionStart)
        .replace(/\D/g, "").length;
      console.log("saving position", position);
      digitPosition.current = position;

      const unique = [...new Set(processed.map(listPretty))];
      console.log("unique", unique);
      //update list
      update("list", unique);
    } else {
      _setPaste(value);
    }
    wasValid.current = valid;
  };

  return (
    <Fade>
      <div className="flex flex-col space-y-2">
        <ConfigureTitle
          title="Create whitelist"
          description="Add all the people allowed to vote"
        />
        <div className="flex flex-col space-y-5">
          <div className="flex flex-col bg-stone-50 text-stone-700 p-3 rounded-lg space-y-2">
            <div className="flex justify-between font-bold items-center">
              <div>Whitelist</div>
              <button
                onClick={() => setShowPaste(!showPaste)}
                className="text-xs font-normal text-opacity-70 underline">
                {showPaste ? "< Enter Numbers" : "Paste Numbers"}
              </button>
            </div>
            {showPaste ? (
              <textarea
                className={
                  "bg-stone-200 w-full rounded-md p-3 min-h-[400px]" +
                  (valid ? "" : " focus:outline-none focus:ring-2 ring-red-500")
                }
                ref={textareaRef}
                value={paste}
                onChange={(e) => setPaste(e.target.value)}
              />
            ) : (
              <div className="space-y-2">
                {list.map((phone) => (
                  <ListItem
                    phone={phone}
                    key={phone}
                    handleDelete={() => handleRemove(phone)}
                    handleToggle={() => handleToggle(phone)}
                    selected={formState.phone === phone}
                  />
                ))}
                <div className="flex justify-between space-x-3">
                  <input
                    className="bg-stone-200 w-full rounded-lg p-3 py-1"
                    placeholder="Enter phone number"
                    value={newNumber}
                    onChange={(e) => setNewNumber(e.target.value)}
                  />
                  <button
                    onClick={handleAdd}
                    disabled={!phoneValidator(newNumber) || hasAlready}
                    className="rounded-lg bg-stone-200 p-3 py-1 disabled:opacity-50">
                    Add
                  </button>
                </div>
              </div>
            )}
            {!valid && (
              <span className="text-xs text-red-500">
                Invalid Entry. make sure there are 9-15 digits per line
              </span>
            )}
            {list.length > 0 && (
              <span className="text-xs text-stone-500">
                *Admins can close the vote early
              </span>
            )}
          </div>
          <TextInput
            title="Name"
            value={formState.creatorName}
            onChange={(e) => update("creatorName", e.target.value)}
            placeholder="Enter your name"
          />
          <div className="bg-stone-200 p-3 rounded-lg flex justify-center">
            <div className="flex flex-col space-y-2 items-center">
              <div className="flex space-x-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                  />
                </svg>
                <span className="font-bold">
                  Each number will be texted a link
                </span>
              </div>
              {formState.creatorName && formState.title && (
                <span className="text-xs text-stone-700">
                  {formState.creatorName} has invited you to vote on {'"'}
                  {formState.title}
                  {'"'}!
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default List;
