import ObjectID from "bson-objectid";

// const getStorage = () => (import.meta.env.PROD ? localStorage : sessionStorage);
// const storage = getStorage();

const storage = sessionStorage;

export const getUser = () => {
  const stored =
    storage.getItem("user_id") || sessionStorage.getItem("user_id");
  if (stored) {
    // console.log("from storage", stored);
    return stored;
  } else {
    const newId = new ObjectID();
    storage.setItem("user_id", newId);
    return newId;
  }
};
