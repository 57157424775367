import SyncLoader from "react-spinners/SyncLoader";

const Loading = () => (
  <div className="w-full min-h-screen flex flex-col justify-center items-center space-y-10">
    <SyncLoader
      loading={true}
      size={15}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
);

export default Loading;
