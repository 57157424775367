// import { sliders, checkSquare, scale, checkCircle } from "./badges";
import { SCOREMAX } from "../utils/values";

export const isQueue = ["B", "R", "COND"];
export const hasVoteWeights = ["S", "A", "M", "STAR"];
export const hasGlobalWeights = ["P", "C", "B"];

export const voteTitles = {
  S: "Score Vote",
  M: "Majority Vote",
  A: "Approval Vote",
  R: "Ranked-Choice Vote",
  B: "Borda Count Vote",
  C: "Cumulative Vote",
  P: "Plurality Vote",
  // STAR: "STAR Vote",
  COND: "Condorcet Vote",
};

export const voteDescriptions = {
  S: `Rate each option from 0-${SCOREMAX}, ${SCOREMAX} being best.`,
  M: `Rate each option from 0-${SCOREMAX}, ${SCOREMAX} being best.`,
  A: "Select all the options you like.",
  R: "Drag and drop the options to rank them, top being best.",
  B: "Drag and drop the options to rank them, top being best.",
  C: "Distribute your votes across the options.",
  P: "Pick one option.",
  // STAR: "Rate each option from 0-10, top two go to a runoff.",
  COND: "Drag and drop the options to rank them, top being best.",
};

export const lowerBetter = ["R"];

export const voteTypes = [
  {
    id: "A",
    type: "Approval",
    description: "Check every option you like, most broadly liked option wins.",
    precision: 1,
    fairness: 3,
    speed: 3,
    tag: {
      text: "fast, fair",
      //  badge: checkSquare, color: "bg-red-100"
    },
  },
  {
    id: "S",
    type: "Score",
    alt: ["Range"],
    description: "Rate each option 1-10, Highest average wins.",
    category: "type",
    precision: 3,
    fairness: 3,
    speed: 1,
    tag: {
      text: "most precise",
      // badge: sliders, color: "bg-green-100"
    },
  },
  {
    id: "P",
    type: "Plurality",
    alt: ["First-Past-The-Post"],
    description:
      "Pick one option, most votes wins. Classic voting, but with classic problems like vote splitting.",
    category: "type",
    precision: 1,
    fairness: 1,
    speed: 3,
    tag: {
      text: "fastest",
      //  badge: checkCircle, color: "bg-red-100"
    },
  },
  {
    id: "R",
    type: "Instant Runoff",
    alt: ["Ranked-Choice", "Alternative Vote"],
    description:
      "Rank options in order. Repeatedly eliminate last place and redistribute votes until one wins.",
    category: "type",
    precision: 2,
    fairness: 2,
    speed: 2,
  },
  {
    id: "M",
    type: "Majority",
    description:
      "Rate each option 1-10, Highest median wins. Like score voting but less swayed by extreme votes.",
    category: "type",
    precision: 2,
    fairness: 3,
    speed: 1,
    // tag: {
    //   text: "resistant to extremes",
    //   badge: scale,
    //   color: "bg-green-100",
    // },
  },
  // {
  //   id: "STAR",
  //   type: "STAR",
  //   alt: ["Score Then Automatic Runoff"],
  //   description:
  //     "Rate each 0-10. Top two go to a runoff. Like score voting but ignores extreme votes.",
  //   category: "type",
  //   precision: 2,
  //   fairness: 3,
  //   speed: 1,
  // },
  {
    id: "B",
    type: "Borda Count",
    description:
      "Rank options in order. Last place gets 0 points, second to last gets 1, etc. Most total points wins.",
    category: "type",
    precision: 2,
    fairness: 2,
    speed: 2,
  },
  {
    id: "C",
    type: "Cumulative",
    description:
      "You get multiple votes to spend across the options. Most total votes wins.",
    category: "type",
    precision: 3,
    fairness: 1,
    speed: 2,
  },
  {
    id: "COND",
    type: "Condorcet",
    alt: ["Copeland"],
    description:
      "Rank options in order. Each option does a plurality mini-election against every other option. Whoever wins the most matchups wins overall.",
    precision: 2,
    fairness: 2,
    speed: 2,
  },
];

export const guestOptions = [
  {
    id: false,
    type: "Predefined Options",
    description: "Options are set at the beginning",
    category: "guest",
  },
  {
    id: true,
    type: "Allow Adding Options",
    description: "Voters can add new options to vote on",
    category: "guest",
  },
];

export const securityOptions = [
  {
    id: "NONE",
    type: "Anyone with the link (not secure)",
    description:
      "Any user with the link can vote. There is some built-in prevention of double voting.",
    category: "security",
  },
  {
    id: "PHONE",
    type: "Text Verification",
    description: "Users must verify their phone number to vote",
    category: "security",
  },
  {
    id: "WHITELIST",
    type: "Whitelist",
    description:
      "Users must verify their identity and be on the whitelist to vote",
    category: "security",
  },
];

export const cognitoOptions = [
  {
    id: "T",
    type: "Total Visibility",
    description: "Everyone's votes are visible",
    category: "cognito",
  },
  {
    id: "AA",
    type: "Allow Anonymous",
    description:
      "By default votes are visible, but people can vote anonymously",
    category: "cognito",
  },
  {
    id: "MA",
    type: "Forced Anonymous",
    description: "All votes are anonymous",
    category: "cognito",
  },
];

export const giftingOptions = [
  {
    id: true,
    type: "Direct Democracy",
    description: "Voters must vote to represent themselves",
    category: "gifting",
  },
  {
    id: false,
    type: "Allow Vote Gifting",
    description: "Voters can entrust their vote to others",
    category: "gifting",
  },
];

export const timeOptions = [
  {
    id: "LIVE",
    type: "Live (Never Ends)",
    description: "Voting never ends, results are updated in real-time",
    category: "deadline_option",
  },
  {
    id: "1m",
    type: "1 Minute",
    description: "Voting ends after 1 minute",
    category: "deadline_option",
  },
  {
    id: "5m",
    type: "5 Minutes",
    description: "Voting ends after 5 minutes",
    category: "deadline_option",
  },
  {
    id: "10m",
    type: "10 Minutes",
    description: "Voting ends after 10 minutes",
    category: "deadline_option",
  },
  {
    id: "30m",
    type: "30 Minutes",
    description: "Voting ends after 30 minutes",
    category: "deadline_option",
  },
  {
    id: "1h",
    type: "1 Hour",
    description: "Voting ends after 1 hour",
    category: "deadline_option",
  },
  {
    id: "1d",
    type: "1 Day",
    description: "Voting ends after 1 day",
    category: "deadline_option",
  },
  {
    id: "1w",
    type: "1 Week",
    description: "Voting ends after 1 week",
    category: "deadline_option",
  },
];

const map = [
  ...voteTypes,
  ...guestOptions,
  ...giftingOptions,
  ...cognitoOptions,
  ...timeOptions,
  ...securityOptions,
].reduce((acc, curr) => {
  acc[`${curr.id.toString()}_${curr.category}`] = curr;
  return acc;
}, {});

export const label = (id, category) =>
  id === undefined ? null : map[`${id.toString()}_${category}`]?.type;
