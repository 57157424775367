import ScoreBar from "../components/ScoreBar";
import { motion } from "framer-motion";
import { voteTypes } from "../utils/data";
import ConfigureTitle from "../components/ConfigureTitle";

const VoteOption = ({
  title,
  alt,
  description,
  precision,
  fairness,
  speed,
  tag,
  handleClick,
}) => {
  const titleBadge = (
    <div className="flex justify-between">
      <div className="flex flex-col space-y-0">
        <div className="text-xl">{title}</div>
        <div className="text-stone-700 text-xs opacity-40 h-3">
          {alt && alt.join(", ")}
        </div>
      </div>

      {tag && (
        <div className="bg-stone-50 flex items-center space-x-1 rounded-full p-1 px-3 h-6">
          {tag.badge && <div className="pl-0.5">{tag.badge}</div>}
          <div className="text-sm">{tag.text}</div>
        </div>
      )}
    </div>
  );

  const desc = (
    <div className="text-stone-700 text-opacity-60">{description}</div>
  );

  const scores = (
    <div>
      <div className="inline-grid grid-cols-2 gap-x-4">
        <div className="text-stone-700 text-opacity-60">Precise</div>
        <ScoreBar n={precision} />
        <div className="text-stone-700 text-opacity-60">Fair</div>
        <ScoreBar n={fairness} />
        <div className="text-stone-700 text-opacity-60">Easy</div>
        <ScoreBar n={speed} />
      </div>
    </div>
  );

  return (
    <div
      className="p-4 flex flex-col cursor-pointer rounded-md bg-stone-200 h-full space-y-2 hover:bg-stone-300 transition-all"
      onClick={handleClick}>
      {titleBadge}
      {desc}

      <div className="hidden md:flex flex-col h-full">
        <div className="flex-1" />
        {scores}
      </div>
    </div>
  );
};

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const ChooseVote = ({ update }) => {
  return (
    <div className="space-y-2">
      <ConfigureTitle
        title="Choose your vote type"
        description="Each vote type has different strengths and weaknesses."
      />
      <motion.div
        className="flex flex-col md:grid md:grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4 md:auto-rows-fr"
        variants={container}
        initial="hidden"
        animate="show">
        {voteTypes
          .sort((a, b) => {
            function val(x) {
              return x.precision + x.fairness + x.speed;
            }
            return val(b) - val(a);
          })
          .map((type) => (
            <motion.div key={type.id} variants={item}>
              <VoteOption
                title={type.type}
                alt={type.alt}
                description={type.description}
                precision={type.precision}
                fairness={type.fairness}
                speed={type.speed}
                tag={type.tag}
                handleClick={() => update("type", type.id)}
              />
            </motion.div>
          ))}
      </motion.div>
    </div>
  );
};

export default ChooseVote;
