import { trashCan } from "../utils/badges";
import Fade from "./Fade";
import TextInput from "./TextInput";
import { useState } from "react";

const OptionDisplay = ({ n, update, option, remove, onEnter }) => {
  const [show, setShow] = useState(false);
  const { title, description } = option;
  return (
    <Fade>
      <div className="p-4 bg-stone-50 rounded-lg border-dotted border-2 border-stone-300 space-y-4">
        <div className="flex justify-between">
          <span className="font-bold">Option {n + 1}</span>
          <button onClick={remove}>{trashCan}</button>
        </div>
        <div className="">
          <div className="flex flex-col space-y-2">
            <TextInput
              title="Title"
              value={title}
              onChange={(e) => update({ ...option, title: e.target.value })}
              placeholder="name me!"
              onEnter={onEnter}
              id={`option-${option.id}`}
            />
            {show && (
              <TextInput
                title="Description"
                value={description}
                onChange={(e) =>
                  update({ ...option, description: e.target.value })
                }
                placeholder="describe me!"
                onEnter={onEnter}
              />
            )}
            {(!description || description.length === 0) && (
              <button
                className="text-xs text-left underline opacity-70"
                onClick={() => setShow(!show)}>
                {show ? "Remove" : "Add"} Description...
              </button>
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default OptionDisplay;
