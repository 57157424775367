const ErrorBar = ({ errors }) => {
  return (
    <>
      {errors.length > 0 && (
        <div className="flex flex-col w-full w-full p-4 bg-red-500 rounded-lg">
          <h1 className="font-bold text-white">{`Error${
            errors.length > 1 ? "s" : ""
          }`}</h1>
          {errors.map((error) => (
            <p className="text-white">- {error}</p>
          ))}
        </div>
      )}
    </>
  );
};

export default ErrorBar;
