import { securityOptions, timeOptions } from "../utils/data";
import Fade from "../components/Fade";
import ConfigureTitle from "../components/ConfigureTitle";
import TextInput from "../components/TextInput";
import Select from "../components/Select";
import phoneValidator from "../utils/phoneValidator";

const Configure = ({ formState, update, showAdvanced, setShowAdvanced }) => {
  return (
    <Fade>
      <div className="flex flex-col space-y-2">
        <ConfigureTitle
          title="Configure your vote"
          description="Choose a title. Description is optional."
        />
        <div className="flex flex-col space-y-3">
          <TextInput
            title="Title"
            value={formState.title}
            onChange={(e) => update("title", e.target.value)}
            placeholder="Enter title"
          />
          <TextInput
            title="Description"
            value={formState.description}
            onChange={(e) => update("description", e.target.value)}
            placeholder="Enter description"
          />
          <Select
            title="Deadline"
            value={formState.deadline_option}
            options={timeOptions}
            onChange={(v) => update("deadline_option", v)}
          />
          <Select
            title="Security"
            value={formState.security}
            options={securityOptions}
            onChange={(v) => update("security", v)}
          />
          {formState.security === "PHONE" && (
            <TextInput
              error={
                formState.phone &&
                formState.phone.length > 0 &&
                !phoneValidator(formState.phone)
              }
              title="Phone number"
              value={formState.phone}
              onChange={(e) => update("phone", e.target.value)}
              placeholder="Enter your phone number"
            />
          )}
          {formState.type === "C" && (
            <TextInput
              title="Number of votes"
              value={formState.numVotes}
              onChange={(e) => update("numVotes", e.target.value)}
              placeholder="Enter number of votes"
            />
          )}
        </div>
      </div>
    </Fade>
  );
};

export default Configure;
