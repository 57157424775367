const Footer = () => {
  return (
    <footer className="text-center text-stone-700 p-3 bg-stone-200">
      <p className="flex text-xs space-x-3 items-center justify-center">
        <span>
          <span>Created by </span>
          <a href="https://www.xandervarga.me " className="text-stone-700 underline">
            Xander Varga
          </a>
        </span>
        <button
          className="bg-stone-700 text-stone-200 rounded-full px-3 py-1"
          onClick={() => window.location.replace("feedback")}>
          feedback
        </button>
      </p>
    </footer>
  );
};

export default Footer;
