const E164format = (phone) => {
  const digitsOnly = phone.replace(/\D/g, "");
  if (digitsOnly.length < 10) return phone;
  const nationalNumber =
    digitsOnly.length === 10 ? digitsOnly : digitsOnly.slice(-10);
  const countryCode = digitsOnly.length > 10 ? digitsOnly.slice(0, -10) : "1";
  return `+${countryCode}${nationalNumber}`;
};

export default E164format;

