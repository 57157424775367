const WarningBar = ({ warning }) => {
  return (
    <>
      {warning && (
        <div className="flex flex-col w-full w-full p-4 bg-yellow-500 rounded-lg">
          <h1 className="font-bold text-white">Warning</h1>
          <p className="text-white">{warning}</p>
        </div>
      )}
    </>
  );
};

export default WarningBar;
