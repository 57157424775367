import { useState, useRef } from "react";

type Option = {
  type: string;
  id: string;
};

type SelectProps = {
  title: string;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  className?: string;
};
// hover:bg-stone-300
// hover:border-stone-300
// focus-within:outline-none
// focus-within:border-stone-400
// focus-within:hover:border-stone-400

const down = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="size-5">
    <path
      fillRule="evenodd"
      d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
      clipRule="evenodd"
    />
  </svg>
);

const up = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="size-5">
    <path
      fillRule="evenodd"
      d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
      clipRule="evenodd"
    />
  </svg>
);

const Select = ({ title, value, options, onChange }) => {
  const [open, setOpen] = useState(false);
  const [focus, _setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const dropdownRef = useRef(null);

  const setFocus = (value) => {
    if (!value) {
      setOpen(false);
      setHover(false);
    }
    _setFocus(value);
  };

  const style = focus
    ? hover
      ? "border-stone-400 bg-stone-300"
      : "border-stone-400"
    : hover
    ? "border-stone-300 bg-stone-300"
    : "border-stone-200";

  const handleOptionClick = (id) => {
    onChange(id);
    setFocus(false);
  };

  return (
    <div
      className="relative"
      ref={dropdownRef}
      onBlur={(e) => {
        console.log(dropdownRef.current.contains(e.relatedTarget));
        if (!dropdownRef.current.contains(e.relatedTarget)) setFocus(false);
      }}
      onFocus={() => setFocus(true)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <button
        tabIndex={0}
        className={
          "transition-all w-full bg-stone-200 border-2 rounded-lg px-4 py-2.5 text-left flex flex-col " +
          style
        }
        onClick={() => setOpen(!open)}>
        <span className="text-stone-700 font-bold">{title}</span>
        <div className="flex justify-between items-center">
          {options.find((option) => option.id === value)?.type}
          {open ? up : down}{" "}
        </div>
      </button>
      {open && (
        <div className="ease-in absolute w-full mt-1 bg-stone-200 rounded-lg z-10 py-3 shadow-sm">
          {options.map((option) => (
            <button
              key={option.id}
              className="w-full text-left px-4 h-8 hover:bg-stone-300"
              onMouseEnter={() => setHover(false)}
              onTouchEnd={(e) => {
                e.preventDefault();
                handleOptionClick(option.id);
              }}
              onClick={() => handleOptionClick(option.id)}>
              {option.type}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
