import { label } from "../utils/data";

const checkCircle = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="size-6">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
);

const check = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
    className="size-4">
    <path
      fillRule="evenodd"
      d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
      clipRule="evenodd"
    />
  </svg>
);

const ProgressList = ({
  formState,
  pageState,
  setPageState,
  validation,
  PageStates,
}) => {
  let arr = [
    "voteType",
    // "type",
    "configure",
    // "deadline_option",
    // "security",
    // "guest",
    "addOptions",
  ];

  if (formState.security === "WHITELIST") {
    arr.push("WHITELIST");
  }

  const max = (a, b) => (a > b ? a : b);

  const done = (pageState) => validation(formState, pageState, 0).length === 0;

  const canClick = (pageState) =>
    validation(formState, PageStates[max(PageStates.indexOf(pageState) - 1, 0)])
      .length === 0;

  const titleMap = {
    voteType: (
      <button onClick={() => setPageState("voteType")}>Vote Type</button>
    ),
    configure: (
      <button
        className="disabled:opacity-50"
        onClick={() => setPageState("configure")}
        disabled={!canClick("configure")}>
        Configure
      </button>
    ),
    addOptions: (
      <button
        className="disabled:opacity-50"
        onClick={() => setPageState("addOptions")}
        disabled={!canClick("addOptions")}>
        Add Options
      </button>
    ),
    WHITELIST: (
      <button
        onClick={() => setPageState("list")}
        className="disabled:opacity-50"
        disabled={!canClick("list")}>
        Whitelist
      </button>
    ),
  };

  const indexofPage = arr.indexOf(pageState) + 1;

  arr = [
    ...arr.slice(0, indexofPage),
    ...arr.slice(indexofPage).filter((c) => titleMap[c]),
  ];

  return (
    <div className="flex flex-col space-y-10">
      {arr.map((c) => {
        const style = titleMap[c]
          ? pageState === c
            ? "font-bold"
            : ""
          : "lg:ml-10";
        const content = titleMap[c] ? (
          <div className="flex space-x-2 items-center">
            <div>{titleMap[c]}</div>
            {done(c) ? check : null}
          </div>
        ) : label(formState[c], c) ? (
          <div className="flex space-x-2 items-center">
            {checkCircle}
            <div>{label(formState[c], c)}</div>
          </div>
        ) : null;
        // `no content for ${c}`

        return (
          <span key={c} className={style}>
            {content}
          </span>
        );
      })}
    </div>
  );
};

export default ProgressList;
