import axios from "axios";
import { useState } from "react";
import url from "../utils/url";

const Feedback = () => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios
      .post(url + "feedback", { text })
      .then(() => {
        setDone(true);
      })
      .catch((err) => {
        setError(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return (
      <div className="flex flex-col space-y-4 min-h-screen p-6 pt-20">
        <span>Loading...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col space-y-4 min-h-screen p-6 pt-20 items-center">
        <span>There was an error submitting your feedback.</span>
        <div>
          <button
            className="flex-1 px-4 py-2 text-stone-200 bg-stone-700 rounded-full border border-stone-700"
            onClick={() => window.location.replace("/")}>
            Use Votey
          </button>
        </div>
      </div>
    );
  }

  if (done) {
    return (
      <div className="flex flex-col space-y-4 min-h-screen p-6 pt-20 items-center">
        <span>Feedback Submitted.</span>
        <div>
          <button
            className="flex-1 px-4 py-2 text-stone-200 bg-stone-700 rounded-full border border-stone-700"
            onClick={() => window.location.replace("/")}>
            Use Votey
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-4 min-h-screen p-6 pt-20">
      <div className="text-3xl sm:text-4xl font-serif font-bold">Feedback</div>
      <div className="bg-stone-200 rounded-md p-3 px-4 max-w-[400px]">
        <label className="flex flex-col space-y-3">
          <textarea
            className="font-normal text-stone-700 w-full bg-stone-200 placeholder-opacity-50 focus:outline-none"
            placeholder="Enter feedback"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </label>
        <span className="text-xs"></span>
      </div>
      <div>
        <button
          className="bg-stone-700 text-stone-200 rounded-full px-3 py-1"
          onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Feedback;
