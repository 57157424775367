import { useState, useEffect } from "react";
import axios from "axios";
import BaseUrl from "../utils/url";
import ErrorBar from "../components/ErrorBar";
import WarningBar from "../components/WarningBar";
import {
  voteTypes,
  securityOptions,
  giftingOptions,
  cognitoOptions,
  guestOptions,
} from "../utils/data";
import { nanoid } from "nanoid";
import ProgressList from "../components/ProgressList";
import Fade from "../components/Fade";
import ChooseVote from "../pages/ChooseVote";
import Configure from "../pages/Configure";
import AddOptions from "../pages/AddOptions";
import List from "../pages/List";
import Loading from "../pages/Loading";
import { getUser } from "../utils/get";
import usePersistentState from "../hooks/usePersistentState";
import E164format from "../utils/E164format";
import phoneValidator from "../utils/phoneValidator";

const isBlank = (str) => !str || /^\s*$/.test(str);

type FormOption = {
  title: string;
  description: string;
};

type FormState = {
  title: string;
  description: string;
  type: string;
  numVotes: number;
  guest: boolean;
  security: string;
  cognito: string;
  gifting: boolean;
  password: string;
  options: [FormOption];
};

type PageState = "voteType" | "configure" | "addOptions" | "list";
const _PageStates: [PageState] = ["voteType", "configure", "addOptions"];

const ActionButton = ({
  isFinish,
  submitTopic,
  _continue,
  errors,
  handleDisabledClick,
}) => (
  <button
    disabled={errors.length > 0}
    className="w-full rounded-full px-7 p-2 transition-all bg-stone-700 font-bold text-stone-100 active:bg-stone-500 hover:bg-stone-600 disabled:opacity-50 disabled:bg-stone-700 "
    onClick={isFinish ? submitTopic : _continue}
    onPointerDown={handleDisabledClick}>
    {isFinish ? "Finish" : "Continue"}
  </button>
);

const CreateTopic = () => {
  const [pageState, setPageState, clearPageState] =
    usePersistentState<PageState>("pageState", "voteType");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showError, setShowError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [cloning, setCloning] = useState(true);
  const [formState, setFormState, clearFormState] =
    usePersistentState<FormState>("formState", {
      title: "",
      description: "",
      type: "",
      deadline_option: "1h",
      numVotes: 10,
      guest: false,
      security: "NONE",
      cognito: "AA",
      gifting: false,
      password: "",
      options: [],
      list: [],
      phone: "",
      creatorName: "",
    });

  const creator = getUser();

  const haslist = formState.security === "WHITELIST";

  const PageStates = [..._PageStates, ...(haslist ? ["list"] : [])];

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id_to_clone = urlParams.get("clone");
    if (!id_to_clone) {
      setCloning(false);
      return;
    }

    window.history.replaceState(
      window.history.state,
      "",
      window.location.pathname
    );

    axios.get(`${BaseUrl}pack/${id_to_clone}`).then(({ data }) => {
      console.log(data);
      const pack = data.pack;
      const form = JSON.parse(pack);
      setFormState(form);
      setTimeout(() => {
        setPageState(PageStates[PageStates.length - 1]);
        setCloning(false);
      }, 0);
    });
  }, []);

  useEffect(() => {
    window.history.pushState({ pageState }, "", "");
    const handlePopState = (event) => {
      if (event.state?.pageState) {
        setPageState(event.state.pageState);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [pageState]);

  if (submitted || cloning) {
    return <Loading />;
  }

  const Validate = (form, pageState) => {
    const errors = [];

    if (!form.type) errors.push("must choose a voting type");
    if (pageState === "voteType") return errors;
    if (!form.title) errors.push("must have a title");
    if (form.type === "C" && form.numVotes <= 0)
      errors.push(
        "for cumulative voting, number of votes must be greater than 0"
      );
    if (!form.security) errors.push("must choose a security setting");
    if (!form.cognito) errors.push("must choose an anonymity setting");
    if (form.gifting === undefined) errors.push("must choose a gift setting");
    if (form.guest === undefined) errors.push("must choose a guest setting");
    if (form.security === "PASS" && !form.password)
      errors.push("must set a password");
    if (form.security === "PHONE" && !form.phone)
      errors.push("must set a phone number");
    if (form.security === "PHONE" && !phoneValidator(form.phone))
      errors.push("invalid phone number");
    if (pageState === "configure") return errors;

    const hasDuplicate =
      [...new Set(formState.options.map((o) => o.title))].length !==
      formState.options.length;
    if (hasDuplicate) errors.push("options can't have the same label");
    const hasBlank = formState.options.some((o) => isBlank(o.title));
    if (hasBlank) errors.push("an option has a blank label");
    if (!formState.guest && formState.options.length < 2)
      errors.push("Provide at least 2 options");
    if (pageState === "addOptions") return errors;
    if (haslist && form.list.length === 0)
      errors.push("Can't have an empty list");
    if (haslist && form.creatorName === "")
      errors.push("Must provide your name the text");

    if (errors.length === 0) {
      if (showError) setShowError(false);
    }

    return errors;
  };

  const ConfigureWarning = (form) => {
    if (form.type === "C" && Number(form.numVotes) === 1)
      return "Cumulative voting with 1 vote is the same as plurality.";
    return "";
  };

  const update = (field, value) => {
    // console.log("updating", field, value);
    if (field === "type") setPageState("configure");
    setFormState((prev) => ({ ...prev, [field]: value }));
    // console.log("set", field, "to", value);
  };

  const submitTopic = () => {
    const payload = {
      ...formState,
      options: formState.options
        .filter((o) => !isBlank(o.title))
        .map((o, index) => ({
          title: o.title,
          description: o.description,
          index,
        })),
      creator,
      password: formState.password || null,
      created_at: new Date(),
      numVotes: formState.type === "C" ? formState.numVotes : undefined,
      phone: E164format(formState.phone),
      pack: JSON.stringify(formState),
    };

    axios
      .post(`${BaseUrl}topics`, payload)
      .then(({ data }) => {
        if (data.url) {
          window.location.href = data.url;
          setSubmitted(true);
          clearFormState();
          clearPageState();
        } else {
          console.error("Error creating topic: no url:", url);
          alert("Error:\n" + JSON.stringify(data));
          setSubmitted(false);
          setPageState("voteType");
        }
      })
      .catch((error) => {
        console.error("Error creating topic:", error);
        alert("Error:\n" + error.message || error);
        setSubmitted(false);
      });
  };

  const states = {
    voteType: ChooseVote,
    configure: Configure,
    addOptions: AddOptions,
    list: List,
  };

  const Item = states[pageState];

  const _continue = () => {
    const index = PageStates.indexOf(pageState) + 1;
    if (index < PageStates.length) {
      setPageState(PageStates[index]);
      setShowError(false);
    }
  };

  const back = () => {
    const index = PageStates.indexOf(pageState) - 1;
    if (index >= 0) {
      setPageState(PageStates[index]);
      setShowError(false);
    }
  };

  const handleDisabledClick = (e) => {
    if (e.target.disabled === true) {
      e.preventDefault();
      e.stopPropagation();
      setShowError(true);
    }
  };

  const errors = Validate(formState, pageState);
  const warning = pageState === "configure" && ConfigureWarning(formState);

  const maxWidth = pageState === "voteType" ? "" : " max-w-[400px]";

  return (
    <div className="flex mx-auto p-6 pt-20 h-full w-full">
      <div className="sm:block sm:flex-1">
        <div className="hidden lg:block lg:min-w-[300px]  p-10">
          <ProgressList
            formState={formState}
            pageState={pageState}
            setPageState={setPageState}
            showAdvanced={showAdvanced}
            validation={Validate}
            PageStates={PageStates}
          />
        </div>
      </div>
      <div
        className={
          "flex flex-col flex-[4] space-between h-full w-full space-y-4" +
          maxWidth
        }>
        <Fade>
          <Item
            formState={formState}
            update={update}
            showAdvanced={showAdvanced}
            setShowAdvanced={setShowAdvanced}
          />
        </Fade>
        <div className="h-5"></div>
        <WarningBar warning={warning} />
        {showError && <ErrorBar errors={errors} />}
        {pageState === PageStates[PageStates.length - 1] ||
        pageState !== "voteType" ? (
          <div className="flex space-x-2">
            <button
              className="font-bold rounded-full px-7 p-2 border-2 border-stone-700 text-text hover:bg-stone-200 hover:border-stone-400 transition-all"
              onClick={back}>
              Back
            </button>
            <ActionButton
              isFinish={pageState === PageStates[PageStates.length - 1]}
              submitTopic={submitTopic}
              _continue={_continue}
              errors={errors}
              handleDisabledClick={handleDisabledClick}
            />
          </div>
        ) : null}
      </div>
      <div className="sm:flex-1 lg:min-w-[300px]" />
    </div>
  );
};

export default CreateTopic;
