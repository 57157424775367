const listPretty = (phone) => {
  // Remove all non-digit characters
  const digitsOnly = phone.replace(/\D/g, "");
  const n = digitsOnly.length;

  // China format (13-15 digits): +86 xxx xxxx xxxx
  if (n >= 13 && n <= 15 && digitsOnly.startsWith("86")) {
    return `+86 ${digitsOnly.slice(2, 5)} ${digitsOnly.slice(
      5,
      9
    )} ${digitsOnly.slice(9)}`;
  }

  // Japan format (11-15 digits): +81 xx xxxx xxxx
  if (n >= 11 && n <= 15 && digitsOnly.startsWith("81")) {
    return `+81 ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(
      4,
      8
    )} ${digitsOnly.slice(8)}`;
  }

  // Brazil format (12-13 digits): +55 xx xxxxx xxxx
  if (n >= 12 && n <= 13 && digitsOnly.startsWith("55")) {
    return `+55 ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(
      4,
      9
    )} ${digitsOnly.slice(9)}`;
  }

  // Russia format (11-12 digits): +7 xxx xxx xxxx
  if (n >= 11 && n <= 12 && digitsOnly.startsWith("7")) {
    return `+7 ${digitsOnly.slice(1, 4)} ${digitsOnly.slice(
      4,
      7
    )} ${digitsOnly.slice(7)}`;
  }

  // Mexico format (11-12 digits): +52 xx xxxx xxxx
  if (n >= 11 && n <= 12 && digitsOnly.startsWith("52")) {
    return `+52 ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(
      4,
      8
    )} ${digitsOnly.slice(8)}`;
  }

  // UK format: +44 xxxx xxxxx
  if ((n === 11 || n === 12) && digitsOnly.startsWith("44")) {
    return `+44 ${digitsOnly.slice(2, n - 5)} ${digitsOnly.slice(n - 5)}`;
  }

  // Australia format: +61 x xxxx xxxx
  if ((n === 11 || n === 12) && digitsOnly.startsWith("61")) {
    return `+61 ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(
      3,
      7
    )} ${digitsOnly.slice(7)}`;
  }

  // India format: +91 xxxxx xxxxx
  if (n === 12 && digitsOnly.startsWith("91")) {
    return `+91 ${digitsOnly.slice(2, 7)} ${digitsOnly.slice(7)}`;
  }

  // Germany format: +49 xxx xxxxxxxx
  if ((n === 11 || n === 12) && digitsOnly.startsWith("49")) {
    return `+49 ${digitsOnly.slice(2, 5)} ${digitsOnly.slice(5)}`;
  }

  // France format: +33 x xx xx xx xx
  if ((n === 11 || n === 12) && digitsOnly.startsWith("33")) {
    return `+33 ${digitsOnly.slice(2, 3)} ${digitsOnly.slice(
      3,
      5
    )} ${digitsOnly.slice(5, 7)} ${digitsOnly.slice(7, 9)} ${digitsOnly.slice(
      9
    )}`;
  }

  // Default US/Canada format: +x (xxx) xxx-xxxx
  return `${
    n > 10 ? `+${digitsOnly.slice(0, n - 10)} ` : ""
  }(${digitsOnly.slice(n - 10, n - 7)}) ${digitsOnly.slice(
    n - 7,
    n - 4
  )}-${digitsOnly.slice(n - 4)}`;
};

export default listPretty;